import axios, { AxiosResponse } from 'axios';
import { from, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { IHttpClientConfig } from './IHttpClientConfig';
import { IHttpClientResponse } from './IHttpClientResponse';

class HttpClient {
  get$<T>(url: string, config?: IHttpClientConfig): Observable<IHttpClientResponse<T>> {
    return from(axios.get<T, AxiosResponse<T>>(url, config)).pipe(
      first(),
      map((response: AxiosResponse<T>) => HttpClient.mapAxiosResponseToHttpClientResponse(response))
    );
  }

  post$<T, D = undefined>(
    url: string,
    data?: D,
    config?: IHttpClientConfig
  ): Observable<IHttpClientResponse<T>> {
    return from(axios.post<T, AxiosResponse<T>>(url, data, config)).pipe(
      first(),
      map((response: AxiosResponse<T>) => HttpClient.mapAxiosResponseToHttpClientResponse(response))
    );
  }

  put$<T, D = undefined>(url: string, data?: D, config?: IHttpClientConfig): Observable<IHttpClientResponse<T>> {
    return from(axios.put<T, AxiosResponse<T>>(url, data, config)).pipe(
      first(),
      map((response: AxiosResponse<T>) => HttpClient.mapAxiosResponseToHttpClientResponse(response))
    );
  }

  patch$<T, D = undefined>(
    url: string,
    data?: D,
    config?: IHttpClientConfig
  ): Observable<IHttpClientResponse<T>> {
    return from(axios.patch<T, AxiosResponse<T>>(url, data, config)).pipe(
      first(),
      map((response: AxiosResponse<T>) => HttpClient.mapAxiosResponseToHttpClientResponse(response))
    );
  }

  delete$<T>(url: string, config?: IHttpClientConfig): Observable<IHttpClientResponse<T>> {
    return from(axios.delete<T, AxiosResponse<T>>(url, config)).pipe(
      first(),
      map((response: AxiosResponse<T>) => HttpClient.mapAxiosResponseToHttpClientResponse(response))
    );
  }

  private static mapAxiosResponseToHttpClientResponse<T>(axiosResponse: AxiosResponse<T>): IHttpClientResponse<T> {
    return {
      data: axiosResponse.data as T,
      status: axiosResponse.status,
      statusText: axiosResponse.statusText,
      headers: axiosResponse.headers,
      config: axiosResponse.config,
    };
  }
}

export {
  HttpClient,
  IHttpClientConfig,
  IHttpClientResponse,
};
export default HttpClient;
