import { brandMapping, cardTree } from './cardtype';
import { Brand, BrandDetailsType, CardTreeNode } from './types';
import Utils from './utils';

export interface IBinLookupConfigType {
  defaultCardType: string;
  minMatch: number;
  maxMatch: number;
  supported: string[];
}

class IinLookup {
  readonly minMatch: number;
  readonly maxMatch: number;
  readonly supported: string[];
  readonly default: BrandDetailsType | null;

  constructor(config?: IBinLookupConfigType) {
    const defaultConfig: IBinLookupConfigType = { defaultCardType: '', minMatch: 0, maxMatch: 6, supported: [] };
    config = config || defaultConfig;
    this.minMatch = Object.prototype.hasOwnProperty.call(config, 'minMatch') ? config.minMatch : defaultConfig.minMatch;
    this.maxMatch = Object.prototype.hasOwnProperty.call(config, 'maxMatch') ? config.maxMatch : defaultConfig.maxMatch;

    this.supported = this.getAllBrands();
    if (config?.supported?.length) {
      const { supported } = config;
      supported.forEach((type: string) => {
        if(!this.isSupported(type)) {
          throw Error(`unsupported cardTree ${type}`);
        }
      });
      this.supported = supported as string[];
    }

    this.default = 'defaultCardType' in config ? this.getCard(config.defaultCardType) : null;
  }

  /**
   * Lookup the type of a card
   * @param number Card number to lookup
   * @return BrandDetails for the brand identified
   */
  lookup(number: string): BrandDetailsType {
    let result: BrandDetailsType = { type: '' };
    if (number.length >= this.minMatch) {
      const tmp = brandMapping[this.recursiveLookup(number, cardTree)];
      if (this.isSupported(tmp)) {
        result = tmp;
      }
    }
    if (!result.type && this.default && number.length <= this.maxMatch) {
      result = this.default;
    }
    return result;
  }

  /**
   * ForEachBreak helper function that only runs over supported brands
   * @param callback Callback to run over the supported brands
   * @return first truthy result of the callback or null
   */
  private forEachBreakBrands<returnType>(callback: (card: BrandDetailsType) => returnType): returnType {
    // @ts-ignore
    return Utils.forEachBreak(Object.values(brandMapping), (card: BrandDetailsType) => {
      if (this.isSupported(card)) {
        return callback(card);
      }
    });
  }

  /**
   * All text brand names the wywtem knows about
   * @return array of all text brand names
   */
  private getAllBrands(): string[] {
    return Object.values(brandMapping)
      .map(brand => brand.type)
      .sort();
  }

  /**
   * Test if a brand is supported with the current configuration
   * @param brand the brand to lookup
   * @return Whether this brand is supported
   */
  private isSupported(brand: string | BrandDetailsType): boolean {
    if (brand instanceof Object) {
      brand = brand.type;
    }
    return Utils.inArray(this.supported, brand);
  }

  /**
   * Look up a brand given it's text name (rather than the internal ID)
   * @param type The name of the brand to get the details for
   * @return The details about the named brand
   */
  private getCard(type: string): BrandDetailsType {
    // @ts-ignore
    return this.forEachBreakBrands((card: BrandDetailsType) => {
      if (card.type === type) {
        return card;
      }
    });
  }

  /**
   * Tree key searching function
   * @param number Card number to check against this key
   * @param key Search key to test against
   * @return whether the card number matches this key
   */
  private matchKey(number: string, key: string): boolean {
    const n1 = number.substring(0, key.length);
    let result = n1 === key;
    if (!result && Utils.inArray(key, '-')) {
      const keys = key.split('-');
      const n2 = parseInt(number.substring(0, keys[1].length), 10);
      if (parseInt(keys[0], 10) <= n2 && n2 <= parseInt(keys[1], 10)) {
        result = true;
      }
    }
    return result;
  }

  /**
   * Recursive lookup helper function
   * @param number Card number to lookup
   * @param tree Recursively searched tree branch
   * @return The succesfully found brand for this card number or null
   */
  private recursiveLookup(number: string, tree: CardTreeNode): Brand {
    if (!(tree instanceof Object)) {
      return tree;
    }
    const found: string[] = Object.keys(tree)
      .filter(key => this.matchKey(number, key))
      .sort((a, b) => a.length - b.length);
    // @ts-ignore
    return (
      Utils.forEachBreak(
        found,
        (key: string): Brand => {
          return this.recursiveLookup(number, tree[key]);
        }
      ) ||
      tree.D ||
      null
    );
  }
}

const iinLookup = new IinLookup();
export { iinLookup, IinLookup };
