import { BrandDetailsType, CardTreeType } from './types';
/* tslint:disable:object-literal-key-quotes quotemark max-line-length interface-over-type-literal */
// This file provides support for the following paymenttypes: AMEX, ASTROPAYCARD, DINERS, DISCOVER, JCB, MAESTRO, MASTERCARD, PIBA, VISA
// THIS FILE IS AUTOMATICALLY GENERATED, PLEASE DO NOT EDIT IT HERE
// prettier-ignore
const cardTree: CardTreeType = {
  '5-6': { '56-69': 8 },
  '1': { '11': { '1175': 1 }, '18': { '1801': 1 } },
  '3': {
    '30-31': { '3096-3102': 3 },
    '33': { '3337-3349': 3 },
    '31': { '311-312': { '3112-3120': 3 }, '315': { '3158-3159': 3 } },
    '30': { '308': { '308950': 6 }, '308-309': { '3088-3094': 3 }, '300-305': 5, '309': { '3095': 5 } },
    '37': 4,
    '36': 5,
    '35': { '357': { '357266': 3 }, '352-358': { '3528-3589': 3 } },
    '34': 4,
    '38-39': 5,
  },
  '2': {
    '22-27': { '2221-2720': 2 },
    '22': { '229': { '22922': { '229222': 2, '229224': 2 }, '22924': { '229248': 2 } } },
    '23': {
      '2301': { '23011': { '230117': 2 }, '23013': { '230135': 2 } },
      '2300': { '230020': 2 },
      '2303': { '230377': 2 },
      '2305': { '230579': 2, '230570': 2 },
      '2304': { '230447': 2 },
      '2307': { '230709': 2 },
      '2306': {
        '23064': { '230645': 2 },
        '23065': { '230653-230655': 2 },
        '23066': { '230660': 2 },
        '23068': { '230688': 2 },
      },
    },
  },
  '5': {
    '59': { '594774': 2 },
    '58': { '589274': 7 },
    '55': {
      '559': {
        '5591': { 'D': 2 },
        '5593': { '559310-559311': 2 },
        '5595': { '559597': 2, '559599': 2 },
        '5594': { 'D': 2 },
      },
      '558': { '5589': { '558964-558965': 2 } },
      '555': { '5551': { '555158-555159': 2 } },
      '557': { '5572': { '557241': 2 }, '5571': { '557148': 2 }, '5573-5574': { '557347-557496': 2 } },
      '556': {
        '5566': { '556619': 2 },
        '5567': { '556741': 2 },
        '5560': { '556000': 2 },
        '5561': { '556115-556116': 2 },
        '5562': { '556295-556296': 2 },
        '5563': { '556355': 2 },
      },
      '553': {
        '5531': { '553109': 2 },
        '5534': { '553452': 2 },
        '5535': { '553559': 2 },
        '5538-5539': { '553899-553901': 2 },
      },
      '552': { '5523': { '552355': 2, '552351': 2 } },
    },
    '54': {
      '543': { '5432-5433': { '543299-543300': 2 } },
      '548': { '5482-5483': { '548299-548300': 2 } },
      '540': { '5405': { '540519': 2 } },
    },
    '51': {
      '511': { '5115-5116': { '511597-511600': 2 } },
      '513': { '5139': { '513900': 2 } },
      '514': { '5144': { 'D': 2 }, '5141-5142': { '514198-514200': 2 } },
      '517': { '5171-5172': { '517198-517200': 2 } },
      '516': {
        '5167-5169': { '51673-51697': 2 },
        '5165-5166': { '516598-516600': 2 },
        '5164-5165': { '516499-516501': 2 },
        '5167-5168': { '516798-516804': 2 },
      },
    },
    '50': 8,
    '53': {
      '537': {
        '5372': { '537204': 2, '537207': 2, '537202': 2 },
        '5372-5376': { '53721-53760': 2 },
        '5375-5376': { '53759900-53760060': 2 },
        '5374-5375': { '537492-537507': 2 },
      },
      '536': { '5368-5369': { '536899-536902': 2 } },
      '535': {
        '5351-5353': { '53511-53530': 2 },
        '5354': { '53547-53548': 2 },
        '5354-5358': { '53542-53581': 2 },
        '5354-5355': { '535498-535524': 2 },
      },
      '533': { '5338-5339': { '533896-533900': 2 } },
      '530': { '5305': { '53055': 2 } },
      '538': { '5385': { '53853500-53853507': 2 } },
    },
    '52': {
      '524': { '5243': { '524342': 2 } },
      '520': { '5204': { '520470': 2 } },
      '528': { '5283': { '528393-528394': 2, '528396-528399': 2 } },
      '529': { '5296': { '529610': 2 }, '5290-5291': { '529099-529100': 2 } },
      '525': { '5251': { '525102-525104': 2, '525107-525109': 2 } },
    },
    '51-55': 2,
  },
  '4': {
    'D': 7,
    '48': {
      '487-488': { '4879-4880': 7 },
      '487': { '4875': { 'D': 7 }, '4877': { 'D': 7 }, '4876': { 'D': 7 }, '4878': { '48782': 7, 'D': 7 } },
      '486': { '4868-4869': { '486899-486900': 7 } },
      '483': { '4836-4837': { '483697-483700': 7 } },
      '482': { '4825': { '482501-482599': 7 }, '4829': { '482925': 7 } },
      '481': { '4818-4819': { '481891-481900': 7 } },
      '480': { '4807': { '480724': 7 } },
    },
    '49': {
      '495': {
        '49509': { '495090-495094': 7 },
        '49505-49506': { '495055-495065': 7 },
        '49506': { '495065-495067': 7, '495067-495068': 7 },
        '49504': { '495042': 7 },
        '49503': { '495034': 7 },
        '49502': { '495026': 7 },
      }, '497': { '4975-4976': { '497597-497602': 7 } }, '490': { '4909': { '49096-49097': 7 } },
    },
    '46': {
      '461-462': { '461999-462000': 7 },
      '465': { '4657': { '465703': 7 }, '4650-4651': { '46505100-46510001': 7 }, '4658': { '46583-46587': 7 } },
      '464': { '4641-4642': { '464199000-464200000': 7 } },
      '466': { '4668': { '466805': 7, '466808-466809': 7 }, '4669': { 'D': 7 } },
      '461': { '4616-4617': { '461699-461700': 7 } },
      '460': { '4602-4603': { '460298000-460301000': 7 }, '4601-4602': { '460199-460200': 7 } },
      '462': { '4621-4622': { '462198-462200': 7 } },
    },
    '47': {
      '471': { '4715': { 'D': 7 }, '4711': { '471100': 7 } },
      '476': { '4763': { '47634-47638': 7 }, '4762': { '47622-47626': 7 } },
      '477': { '4774-4775': { '477499000-477500000': 7 } },
      '474': { '4748': { '47488-47489': 7 } },
      '475': { '4751': { '47511-47515': 7 }, '4753': { '475395-475396': 7 } },
      '479': { '4790-4791': { '479099000-479103000': 7 }, '4792-4793': { '479298-479300': 7 } },
    },
    '44': {
      '442': { '4422-4423': { '442298000-442300024': 7 } },
      '441': { '4415-4416': { '441598-441600': 7 } },
      '448': { '4484-4486': 7 },
      '446': { '4462': { 'D': 7 }, '4461-4462': { '446199-446211': 7 } },
      '440': { '4401-4402': { '440199-440200': 7 } },
    },
    '45': {
      '458': {
        '4586': { 'D': 7 },
        '4589': { 'D': 7 },
        '4583': { '458304': 7, '458300': 7, '458302': 7 },
        '4582': { '45822': 7 },
      },
      '459': { '4596-4597': 7, '4596': { '45960': 7 }, '4591-4592': { '459199-459205': 7 } },
      '453': { '4535-4536': { '453597-453601': 7 } },
      '454': { '4542': { '454202-454203': 7, '454205': 7 }, '4547-4548': { '45479999-45480199': 7 } },
      '455': { '4553-4554': { '455399985-455400025': 7 } },
      '456': { '4569': { '456997': 7 }, '4561-4562': { '456199-456202': 7 } },
      '457': { '4571': { 'D': 7 } },
    },
    '42': {
      '421': { '4214-4215': { '421498-421501': 7 } },
      '428': { '4280': { '42809': 7 } },
      '422': { '4224-4225': { '422499-422502': 7 }, '4226-4227': { '422699-422700': 7 } },
      '424': { '4241-4242': { '424199-424200': 7 } },
    },
    '43': {
      '432-433': { '432998-433002': 7 },
      '438': { '4387-4388': { '438799097-438800021': 7 } },
      '439': { '4394-4395': { '439499-439502': 7 }, '4397': { '439793-439798': 7, '439790': 7 } },
      '436': { '4362': 7 },
      '437': { '4372': { '437295': 7 }, '4371': { '437127': 7 }, '4370-4372': 7 },
      '435': { '4358-4359': { '435899000-435900010': 7 }, '4356': { '43568': 7 } },
      '433': { '4330-4331': { '433099-433100': 7 }, '4337-4338': { '433799-433800': 7 } },
      '431': { '4319': { '43193-43197': 7 } },
    },
    '40': {
      '402-403': { '402999-403001': 7 },
      '406': { '4064-4065': { '406498-406500': 7 } },
      '404': { '4046-4047': { '404697-404700': 7 }, '4048-4049': { '404898-404900': 7 } },
      '402': { '4024-4025': { '402499-402500': 7 } },
      '400': { '4005-4006': { '400599-400600': 7 } },
      '408': { '4085-4086': { '408598000-408600000': 7 }, '4087': { 'D': 7 } },
    },
    '41': {
      '414': { '4145-4146': { '414598-414600': 7 }, '4147-4148': { '414794-414800': 7 } },
      '418': { '4181': { '41810': 7 }, '4184': { '418406': 7 } },
      '411': { '4118-4119': { '411899-411900': 7 } },
      '412': { '4123': { '41239200-41239201': 7 } },
    },
  },
  '7': { '73': { '738900-738907': 7 }, '70': { '706159': 2 } },
  '6': {
    '60': {
      '6011': {
        '60110': 9,
        '60112-60114': 9,
        '60118-60119': { '601186-601199': 9 },
        '60117': { '601177-601179': 9, '601174': 9 },
      }, '6012': { '601281': 7 },
    },
    '64-65': { '644-659': 9 },
    '62': { '622': { '622126-622925': 9 }, '628': { '6282-6288': 9 }, '624-626': 9 },
    '67': {
      '675': { '6759': { 'D': 8 } },
      '679': { '6790': { '67903258': 8 }, '6798': { '67983546-67983548': 8 } },
      '671': { '67116': { '67116008': 8 }, '67119': { '67119946-67119948': 8 } },
    },
  },
  '9': {
    '91': { '918838': 2 },
    '90': { '9000000': 8 },
    '98': { '988': { '98832-98833': { '988328-988330': 2 }, '98838': { '988388': 2 } } },
  },
};
// prettier-ignore
const brandMapping: { [key: string]: BrandDetailsType } = {
  '1': {
    'luhn': false,
    'length': [16],
    'cvcLength': [4],
    'type': 'ASTROPAYCARD',
    'format': '(\\d{1,4})(\\d{1,4})?(\\d{1,4})?(\\d+)?',
  },
  '2': {
    'luhn': true,
    'length': [16],
    'cvcLength': [3],
    'type': 'MASTERCARD',
    'format': '(\\d{1,4})(\\d{1,4})?(\\d{1,4})?(\\d+)?',
  },
  '3': {
    'luhn': true,
    'length': [15, 16, 19],
    'cvcLength': [3],
    'type': 'JCB',
    'format': '(\\d{1,4})(\\d{1,4})?(\\d{1,4})?(\\d+)?',
  },
  '4': { 'luhn': true, 'length': [15], 'cvcLength': [4], 'type': 'AMEX', 'format': '(\\d{1,4})(\\d{1,6})?(\\d+)?' },
  '5': {
    'luhn': true,
    'length': [14, 15, 16, 17, 18, 19],
    'cvcLength': [3],
    'type': 'DINERS',
    'format': '(\\d{1,4})(\\d{1,6})?(\\d+)?',
  },
  '6': {
    'luhn': true,
    'length': [19],
    'cvcLength': [0],
    'type': 'PIBA',
    'format': '(\\d{1,4})(\\d{1,4})?(\\d{1,4})?(\\d+)?',
  },
  '7': {
    'luhn': true,
    'length': [13, 16, 19],
    'cvcLength': [3],
    'type': 'VISA',
    'format': '(\\d{1,4})(\\d{1,4})?(\\d{1,4})?(\\d+)?',
  },
  '8': {
    'luhn': false,
    'length': [12, 13, 14, 15, 16, 17, 18, 19],
    'cvcLength': [3],
    'type': 'MAESTRO',
    'format': '(\\d{1,4})(\\d{1,4})?(\\d{1,4})?(\\d+)?',
  },
  '9': {
    'luhn': true,
    'length': [14, 15, 16, 17, 18, 19],
    'cvcLength': [3],
    'type': 'DISCOVER',
    'format': '(\\d{1,4})(\\d{1,4})?(\\d{1,4})?(\\d+)?',
  },
};
export { cardTree, brandMapping };
